import React, { useContext } from 'react'

import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import {
  Container,
  TwoColumnGrid,
  GridItem,
  InnerContainer
} from '~/utils/styles'

const Cart = () => {
  const {
    store: { checkout },
    
  } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  return (
    <Container>
      <TwoColumnGrid>
        <GridItem>
          <InnerContainer>
            <h2>Basket</h2>
            {line_items}
          </InnerContainer>
     
      </GridItem>
      <GridItem>
        <InnerContainer>
      <h2>Subtotal</h2>
      <p>$ {checkout.subtotalPrice}</p>
      </InnerContainer>
      <InnerContainer>
      
      <h2>Taxes</h2>
      <p>$ {checkout.totalTax}</p>
      </InnerContainer>
      <InnerContainer>
      
      <h2>Total</h2>
      <p>$ {checkout.totalPrice}</p>
      
      </InnerContainer>
      <InnerContainer>
      <button onClick={handleCheckout} disabled={checkout.lineItems.length === 0}>Check out</button>
      </InnerContainer>
      </GridItem>
      </TwoColumnGrid>
    </Container>
  )
}

export default Cart
